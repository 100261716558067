<template>
    <div class="view">
        <el-tabs v-model="intoType" @tab-click="tabClickFun">
            <el-tab-pane label="合作商家" name="1">
                <el-tabs class="statusTabs mt10 pl20 pr20" v-model="shopTableInfo.status" @tab-click="tabClickFun">
                    <el-tab-pane :label="name" :name="id" v-for="(name, id) in statusObj" :key="id">
                    </el-tab-pane>
                </el-tabs>
                <div class="pl24 pr24 pb24 pt10">
                    <div class="mt10 mb10">
                        <el-alert v-if="shopTableInfo.status == 1" title="已通过审核，等待商家支付成功才可以正式入驻" type="warning" show-icon>
                        </el-alert>
                    </div>
                    <div class="d-flex">
                        <div>
                            <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="shopTableInfo.searchName" />
                            <el-button type="primary" @click="shopSearchFun">搜索</el-button>
                        </div>
                    </div>
                    <commonTable :tableData="shopTableInfo.tableData" :loading="shopTableInfo.loading"
                        @handleSizeChange="shopSizeChange" @handleCurrentChange="shopCurrentChange"
                        :currentPage="shopTableInfo.currentPage" :total="shopTableInfo.total">
                        <template v-slot:table>
                            <el-table-column type="index" align="center" label="序号" />
                            <el-table-column prop="typeTitle" align="center" label="进驻栏目" show-overflow-tooltip />
                            <el-table-column prop="shopName" align="center" label="门店名称" show-overflow-tooltip />
                            <el-table-column prop="shopPhone" align="center" label="门店电话" show-overflow-tooltip />
                            <el-table-column prop="name" align="center" label="联系人" show-overflow-tooltip />
                            <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip />
                            <el-table-column prop="createTime" align="center" label="申请时间" show-overflow-tooltip />
                            <el-table-column align="center" label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-button type="text" v-if="scope.row.status === 0"
                                        @click="showApplyModelFun(scope.row)">审 核</el-button>
                                    <el-button type="text" v-if="scope.row.status !== 0"
                                        @click="showApplyModelFun(scope.row)">详 情</el-button>
                                </template>
                            </el-table-column>
                        </template>
                    </commonTable>
                </div>
            </el-tab-pane>
            <el-tab-pane label="企服商家" name="0">
                <el-tabs class="statusTabs mt20 pl20 pr20" v-model="serviceTableInfo.status" @tab-click="tabClickFun">
                    <el-tab-pane :label="name" :name="id" v-for="(name, id) in statusObj" :key="id">
                    </el-tab-pane>
                </el-tabs>
                <div class="pa24">
                    <div class="d-flex">
                        <div>
                            <el-input class="w200 mb10 mr10" placeholder="请输入关键字"
                                v-model="serviceTableInfo.searchName" />
                            <el-button type="primary" @click="serviceSearchFun">搜索</el-button>
                        </div>
                    </div>
                    <commonTable :tableData="serviceTableInfo.tableData" :loading="serviceTableInfo.loading"
                        @handleSizeChange="serviceSizeChange" @handleCurrentChange="serviceCurrentChange"
                        :currentPage="serviceTableInfo.currentPage" :total="serviceTableInfo.total">
                        <template v-slot:table>
                            <el-table-column type="index" align="center" label="序号" />
                            <el-table-column prop="typeTitle" align="center" label="进驻栏目" show-overflow-tooltip />
                            <el-table-column prop="companyName" align="center" label="进驻企业" show-overflow-tooltip />
                            <el-table-column prop="name" align="center" label="联系人" show-overflow-tooltip />
                            <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                            <el-table-column prop="createTime" align="center" label="申请时间" show-overflow-tooltip />
                            <el-table-column align="center" label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-button type="text" v-if="scope.row.status === 0"
                                        @click="showApplyModelFun(scope.row)">审 核</el-button>
                                    <el-button type="text" v-if="scope.row.status !== 0"
                                        @click="showApplyModelFun(scope.row)">详 情</el-button>
                                </template>
                            </el-table-column>
                        </template>
                    </commonTable>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="进驻审核" :visible.sync="showShopApplyModel" 
            :destroy-on-close="true" width="50%" center>
            <el-form :model="shopApplyDetails" ref="shopApplyDetails" label-width="150px"
                v-loading="shopDetailsLoading">
                <el-form-item label="">
                    <img class="logo" :src="shopApplyDetails.shopLogo" alt="">
                </el-form-item>
                <el-row>
                    <el-col :lg="12">
                        <el-form-item label="门店名称：">
                            <div>{{ shopApplyDetails.shopName }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12">
                        <el-form-item label="门店电话：">
                            <div>{{ shopApplyDetails.shopPhone }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="12">
                        <el-form-item label="联系人：">
                            <div>{{ shopApplyDetails.name }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12">
                        <el-form-item label="联系电话：">
                            <div>{{ shopApplyDetails.phone }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="代理类型：">
                            <el-tag class="mr5" v-for="(row, index) in shopApplyDetails.typeList" size="medium"
                                :key="index">
                                {{ row.typeName }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="门店形象照：">
                            <el-button type="primary" @click="showImgModel('门店形象照', shopApplyDetails.shopPicture)">查看照片
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="12">
                        <el-form-item label="营业执照：">
                            <el-button type="primary" @click="showImgModel('营业执照', shopApplyDetails.shopLicense)">查看执照
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12">
                        <el-form-item label="免单承诺：">
                            <el-button type="primary" @click="showImgModel('免单承诺', shopApplyDetails.shopPromise)">查看承诺
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="申请时间：">
                            <div>{{ shopApplyDetails.createTime }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider content-position="left">到店指引</el-divider>
                <el-row>
                    <el-col :lg="12">
                        <el-form-item label="营业时间：">
                            <div>
                                {{ shopApplyDetails.businessHours }}
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12">
                        <el-form-item label="联系电话：">
                            <div>
                                {{ shopApplyDetails.telephone }}
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <el-form-item label="门店地址：">
                            <div>
                                {{ shopApplyDetails.address }}
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-tabs v-model="productType">
                    <el-tab-pane label="折扣商品" name="discount">

                    </el-tab-pane>
                    <el-tab-pane label="免单商品" v-if="shopApplyDetails.isFree == 1" name="free">

                    </el-tab-pane>
                    <div v-if="productType === 'discount'">
                        <div class="mt10 mb10 ml10"
                            v-if="shopApplyDetails.discountType == 0 && shopApplyDetails.discountScale > 0">商品折扣：{{
                                    discountTypeObj[shopApplyDetails.discountType]
                            }}{{((1 - shopApplyDetails.discountScale) * 10)}}折</div>
                        <el-alert :title="'用户消费须知：' + shopApplyDetails.discountShouldKnow" type="warning" show-icon>
                        </el-alert>
                        <div class="myCardView" v-if="shopApplyDetails.discountList && shopApplyDetails.discountList.length > 0">
                            <div class="myCardItem" v-for="(row, index) in shopApplyDetails.discountList" :key="index">
                                <el-card :body-style="{ padding: '10px' }" shadow="always">
                                    <div class="d-flex">
                                        <div class="leftImg flex-c-c">
                                            <img :src="row.goodsImg" alt="">
                                        </div>
                                        <div class="rightText">
                                            <div class="pName text-overflow">{{ row.goodsName }}</div>
                                            <div class="cale"
                                                v-if="shopApplyDetails.discountType == 1 && row.discountScale > 0">
                                                {{ ((1 - row.discountScale) * 10) }}折
                                            </div>
                                            <div class="pPrice"><span class="i">￥</span>{{ row.goodsPrice }}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                        <div v-else class="myCardView pt10 flex-c-c">
                            无商品
                        </div>
                    </div>
                    <div v-if="productType === 'free'">
                        <div class="mt10 mb10 ml10">
                            免单活动日期：{{ shopApplyDetails.freeStartTime }} ~ {{ shopApplyDetails.freeEndTime }}</div>
                        <el-alert :title="'用户消费须知：' + shopApplyDetails.freeShouldKnow" type="warning" show-icon>
                        </el-alert>
                        <div class="myCardView" v-if="shopApplyDetails.freeList && shopApplyDetails.freeList.length > 0">
                            <div class="myCardItem" v-for="(row, index) in shopApplyDetails.freeList" :key="index">
                                <el-card :body-style="{ padding: '10px' }" shadow="always">
                                    <div class="d-flex">
                                        <div class="leftImg flex-c-c">
                                            <img :src="row.goodsImg" alt="">
                                        </div>
                                        <div class="rightText">
                                            <div class="pName text-overflow">{{ row.goodsName }}</div>
                                            <div class="pPrice"><span class="i">￥</span>{{ row.goodsPrice }}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                        <div v-else class="myCardView pt10 flex-c-c">
                            无商品
                        </div>
                    </div>
                </el-tabs>


            </el-form>
            <span slot="footer" class="dialog-footer" v-if="shopApplyDetails.status === 0">
                <el-button type="primary" @click="examineFun('1')">通过</el-button>
                <el-button type="danger" @click="showReasonModel = true">不通过</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="进驻审核" :visible.sync="showServiceApplyModel" 
            :destroy-on-close="true" width="500px" center>
            <el-form :model="serviceApplyDetails" ref="serviceApplyDetails" label-width="150px"
                v-loading="serviceDetailsLoading">
                <el-form-item label="企业名称：">
                    <div>{{ serviceApplyDetails.companyName }}</div>
                </el-form-item>
                <el-form-item label="联系人：">
                    <div>{{ serviceApplyDetails.name }}</div>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <div>{{ serviceApplyDetails.phone }}</div>
                </el-form-item>
                <el-form-item label="代理类型：">
                    <el-tag class="mr5" v-for="(row, index) in serviceApplyDetails.typeList" size="medium" :key="index">
                        {{ row.typeName }}</el-tag>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <div>{{ serviceApplyDetails.createTime }}</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="serviceApplyDetails.status === 0">
                <el-button type="primary" @click="examineFun('1')">通过</el-button>
                <el-button type="danger" @click="showReasonModel = true">不通过</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="不通过原因" :visible.sync="showReasonModel" :destroy-on-close="true"
            width="450px" center>
            <el-input type="textarea" :rows="5" placeholder="请输入不通过原因" v-model="reason">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReasonModel = false">取 消</el-button>
                <el-button type="primary" @click="examineFun('2')">确 认</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="showImgTitle" :visible.sync="showImg" :destroy-on-close="true"
            center>
            <el-carousel :autoplay="false" height="500px">
                <el-carousel-item v-for="item in imgUrlArr" :key="item">
                    <!-- <img height="100%" width="100%" :src="item" alt=""> -->
                    <el-image style="width: 100%; height: 100%" :src="item" fit="scale-down"></el-image>
                </el-carousel-item>
            </el-carousel>
        </el-dialog>
    </div>
</template>

<script>
import {
    getParkIntoCompanyList,
    getParkIntoCompany,
    updateParkIntoCompany,
} from "@/api/association"
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "propertyTable",
    components: {
        commonTable
    },
    data() {
        return {
            //新增/修改配置
            showImg: false,
            imgUrlArr: "",
            showImgTitle: "",
            productType: "discount",
            showReasonModel: false,//不通过原因模态框显示状态
            reason: "",//不通过原因
            showShopApplyModel: false,//进驻商家模态框显示状态
            shopDetailsLoading: false,//进驻商家模态框加载状态
            shopApplyDetails: {//进驻商家详情
                shopName: "",
                shopPhone: "",
                name: "",
                phone: "",
                invitePhone: "",
                typeList: [],
                shopLogo: "",
                shopPicture: "",
                shopLicense: "",
                shopPromise: "",
                //折扣商品
                discountType: "",
                discountList: [],
                discountScale: 0,
                discountShouldKnow: "",
                //免单商品
                isFree: "",
                freeList: [],
                freeShouldKnow: "",
                freeStartTime: "",
                freeEndTime: "",
                businessHours: "",
                telephone: "",
                address: "",
                status: "",
            },
            showServiceApplyModel: false,//进驻企业模态框显示状态
            serviceDetailsLoading: false,//进驻企业模态框加载状态
            serviceApplyDetails: {//进驻企业详情
                companyName: "",
                name: "",
                phone: "",
                typeList: [],
                status: "",
            },
            discountTypeObj: {
                0: "全店商品",
                1: "部分商品"
            },
            updateId: 0,
            //列表配置
            intoType: "1",
            associationId: 0,
            //周边商品配置
            shopTableInfo: {
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                total: 0, //总条数
                tableData: [],
                loading: false, //表格加载
                searchName: "",
                status: "0"
            },
            //服务大厅配置
            serviceTableInfo: {
                searchName: "",
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                total: 0, //总条数
                tableData: [],
                loading: false, //表格加载
                status: "0"
            },
            statusObj: {//审核状态
                0: "待审核", 1: "待支付", 2: "不通过"
            }
        };
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        },
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            if (this.intoType == 1) {
                this.shopTableInfo.currentPage=1
                this.shopList();
            } else if (this.intoType == 0) {
                this.serviceTableInfo.currentPage=1
                this.serviceList();
            }
        }
    },
    created() {
        let checkedAssociation = sessionStorage.getItem('checkedAssociation');
        this.associationId = JSON.parse(checkedAssociation).associationId;
        this.shopList();
    },
    methods: {
        /**@method 显示图片 */
        showImgModel(title, url) {
            this.showImgTitle = title;
            this.showImg = true;
            this.imgUrlArr = url.split(",");
        },
        /**@method 切换分类加载列表 */
        tabClickFun(val) {
            if (this.intoType == 1) {
                this.shopList();
            } else if (this.intoType == 0) {
                this.serviceList();
            }
        },
        /**@method 审核 */
        examineFun(status) {
            let params = {
                status,
                intoId: this.updateId,
            };
            if (status == 2) {
                if (!this.reason) {
                    this.$message({
                        message: "请输入原因",
                        type: "warning"
                    })
                    return;
                }
                params.remark = this.reason;
            }
            updateParkIntoCompany(params).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "审核完成",
                        type: "success"
                    })
                    this.showShopApplyModel = false;
                    this.showServiceApplyModel = false;
                    this.showReasonModel = false;
                    if (this.intoType == 1) {
                        this.shopSearchFun();
                    } else if (this.intoType == 0) {
                        this.serviceSearchFun();
                    }
                } else {
                    this.$message({
                        message: res.message,
                        type: "error"
                    })
                }
            }).catch(err => {
                this.$message({
                    message: err.message,
                    type: "error"
                })
            })
        },
        /**@method 显示审核内容
         * @param {Object} row - 当前点击行的值
         */
        showApplyModelFun(row) {
            this.updateId = row.intoId;
            if (this.intoType == 1) {
                this.showShopApplyModel = true;
            } else if (this.intoType == 0) {
                this.showServiceApplyModel = true;
            }
            this.serviceDetailsLoading = true;
            this.shopDetailsLoading = true;
            getParkIntoCompany({ intoId: row.intoId }).then(res => {
                this.serviceDetailsLoading = false;
                this.shopDetailsLoading = false;
                if (res.data) {
                    let data = res.data;
                    if (this.intoType == 0) {
                        this.serviceApplyDetails = {
                            companyName: data.companyName,
                            name: data.name,
                            phone: data.phone,
                            typeList: data.typeList,
                            status: data.status,
                            createTime:data.createTime?data.createTime.slice(0,10)+" "+data.createTime.slice(11,19):""
                        };
                    } else if (this.intoType == 1) {
                        this.shopApplyDetails = {
                            shopName: data.shopName,
                            shopPhone: data.shopPhone,
                            name: data.name,
                            phone: data.phone,
                            invitePhone: data.invitePhone,
                            typeList: data.typeList,
                            shopLogo: data.shopLogo,
                            shopPicture: data.shopPicture,
                            shopLicense: data.shopLicense,
                            shopPromise: data.shopPromise,
                            //折扣商品
                            //审核表discountScale已废弃，统一使用商品中的discountScale
                            discountType: data.discountType,
                            discountList: data.discountList,
                            discountScale: data.discountList && data.discountList.length > 0 ? data.discountList[0].discountScale : 0,
                            discountShouldKnow: data.discountShouldKnow,
                            //免单商品
                            isFree: data.isFree,
                            freeList: data.freeList,
                            freeShouldKnow: data.freeShouldKnow,
                            freeStartTime: data.freeStartTime,
                            freeEndTime: data.freeEndTime,
                            businessHours: data.businessHours,
                            telephone: data.telephone,
                            address: data.address,
                            status: data.status,
                            createTime:data.createTime?data.createTime.slice(0,10)+" "+data.createTime.slice(11,19):""
                        };
                    }

                }
            })
        },
        /**@method 列表API配置 */
        /**@method 搜索 */
        shopSearchFun() {
            this.shopTableInfo.currentPage = 1;
            this.shopList();
        },
        /**@method 切换行 */
        shopSizeChange(val) {
            this.shopTableInfo.pageSize = val;
            this.shopList();
        },
        /**@method 切换下一页 */
        shopCurrentChange(val) {
            this.shopTableInfo.currentPage = val;
            this.shopList();
        },
        async shopList() {
            let params = {
                intoType: 1,
                status: this.shopTableInfo.status,
                associationId: this.associationId,
                pageNum: this.shopTableInfo.currentPage,
                pageSize: this.shopTableInfo.pageSize
            };
            try {
                this.shopTableInfo.loading = true;
                let res = await getParkIntoCompanyList(params);
                this.shopTableInfo.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    let typeTitle = [];
                    if (row.typeList && row.typeList.length > 0) {
                        for (let type of row.typeList) {
                            typeTitle.push(type.typeName)
                        }
                    }
                    row.typeTitle = typeTitle.join("、")
                    row.createTime=row.createTime.slice(0,10)+" "+row.createTime.slice(11,19)
                    tableData.push(row);
                }
                this.shopTableInfo.tableData = tableData;
                this.shopTableInfo.total = data.pageInfo.total;
            } catch (error) {
                this.shopTableInfo.loading = false;
                this.shopTableInfo.tableData = [];
            }
        },
        /**@method 搜索 */
        serviceSearchFun() {
            this.serviceTableInfo.currentPage = 1;
            this.serviceList();
        },
        /**@method 切换行 */
        serviceSizeChange(val) {
            this.serviceTableInfo.pageSize = val;
            this.serviceList();
        },
        /**@method 切换下一页 */
        serviceCurrentChange(val) {
            this.serviceTableInfo.currentPage = val;
            this.serviceList();
        },
        async serviceList() {
            let params = {
                intoType: 0,
                status: this.serviceTableInfo.status,
                associationId: this.associationId,
                pageNum: this.serviceTableInfo.currentPage,
                pageSize: this.serviceTableInfo.pageSize
            };
            try {
                this.serviceTableInfo.loading = true;
                let res = await getParkIntoCompanyList(params);
                this.serviceTableInfo.loading = false;
                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    let typeTitle = [];
                    if (row.typeList && row.typeList.length > 0) {
                        for (let type of row.typeList) {
                            typeTitle.push(type.typeName)
                        }
                    }
                    row.typeTitle = typeTitle.join("、");
                    row.createTime=row.createTime.slice(0,10)+" "+row.createTime.slice(11,19)
                    tableData.push(row);
                }
                this.serviceTableInfo.tableData = tableData;
                this.serviceTableInfo.total = data.pageInfo.total;
            } catch (error) {
                this.serviceTableInfo.loading = false;
                this.serviceTableInfo.tableData = [];
            }
        }
    },
};
</script>
<style>
.statusTabs .el-tabs__header {
    height: 34px !important;
}

.statusTabs .el-tabs__active-bar {
    width: 80px !important;
}

.statusTabs .el-tabs__nav {
    height: 34px !important;
}

.statusTabs .el-tabs__item {
    width: 80px !important;
    line-height: 34px !important;
    font-size: 12px !important;
    height: 34px !important;
}
</style>
<style lang="scss" scoped>
.logo {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.myCardView {
    max-height: 178px;
    padding-bottom: 10px;
    overflow-y: auto;
    border: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
}

.myCardItem {
    width: 230px;
    margin: 10px;
    margin-bottom: 0px;
}

.leftImg {
    font-size: 26px;
    width: 60px;
    height: 60px;
    color: #fff;

    img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
}

.rightText {
    font-size: 15px;
    margin-left: 10px;
    position: relative;
    width: 130px;

    .cale {
        font-size: 12px;
        color: #999;
    }

    .pName {
        line-height: 20px;
    }

    .pPrice {
        .i {
            font-size: 13px;
        }

        position:absolute;
        bottom:0;
        color:#e03436;
    }
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>